
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import AddressModule from '@/store/modules/address'
import httpHelper from '@/utils/request'
import AddressItem from '@/components/addressItem.vue'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'addresslist',
  components: {
    'address-item': AddressItem
  },
  mixins: [GlobalMixin]
})
export default class AddressList extends Vue {
  @Emit()
  private created () {
    this.refresh()
  }

  @Emit()
  private async refresh () {
    await AddressModule.loadAddresses()
  }

  get addresses () {
    return AddressModule.addresses || []
  }

  @Emit()
  private async goBack () {
    this.$router.back()
  }
}
