
import { Component, Vue, Provide, Watch, Emit, Prop } from 'vue-property-decorator'
import AddressModule from '@/store/modules/address'
import GlobalMixin from '@/mixins/global'
import httpHelper from '@/utils/request'

@Component({
  name: 'addressItem',
  mixins: [GlobalMixin]
})
export default class extends Vue {
  @Provide() private translateX: number = 0
  @Provide() private isFromShopping: any = {}
  @Provide() private sysNo: any = {}
  private startX: number = 0
  private startY: number = 0
  private extend: number = 0

  private touchKey = 0
  private startPoint: any = { x: 0, y: 0 }
  private isDeltaX = false

  @Prop({
    type: Array
  }) addresses!: any[]

  @Watch('addresses')
  private addressesWatch () {
    setTimeout(() => {
      this.bindTouchOption()
    })
  }

  private refresh () {
    this.extend = 0
    this.isDeltaX = false
    AddressModule.loadAddresses()
  }

  @Emit()
  private async setDefaultAddress (sysNo: number) {
    try {
      await httpHelper.post({
        url: 'IUserHome/SetDefaultAddress',
        data: {
          sysNo: sysNo
        },
        type: 'apiv5'
      })
      this.refresh()
      this.$toasted.show('修改默认成功')
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  @Emit()
  private async deleteAddress (sysNo: number) {
    try {
      await httpHelper.post({
        url: 'IUserHome/DeleteAddress',
        data: {
          sysNo: sysNo
        },
        type: 'apiv5'
      })
      this.refresh()
      this.$toasted.show('删除成功')
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  @Emit()
  private isAngleLeft (dx: number, dy: number) {
    const x = Math.abs(dx)
    const y = Math.abs(dy)
    return !(x < 5 || (x >= 5 && y >= x * 1.73))
  }

  @Emit()
  private async selectAddress (address: any) {
    AddressModule.setAddress(address.addressSysNo)
    const { sysNo, isFromShopping } = this.$route.query
    this.sysNo = sysNo
    this.isFromShopping = isFromShopping
    if (this.isFromShopping === '101') {
      this.$router.push({ path: `/house/detail/${this.sysNo}`, query: { addressSysNo: address.addressSysNo, ReceiveContact: address.receiveContact, ReceivePhone: address.receiveCellPhone, Address: address.receiveAreaName + address.receiveAddress } })
    } else if (this.isFromShopping === '102') {
      this.$router.go(-1)
    }
  }

  private bindTouchOption () {
    (this.$refs['addressItem'] as any).forEach((el: any, key: number) => {
      if (el.bindedTouch) {
        return
      }
      el.bindedTouch = true
      el.addEventListener('touchstart', this.touchStart, false)
      el.addEventListener('touchmove', (e: any) => this.touchMove(e, key), false)
      el.addEventListener('touchend', this.touchEnd, false)
    })
  }

  private touchStart (e:any) {
    const touches = e.changedTouches[0]
    this.startPoint = {
      x: touches.pageX,
      y: touches.pageY
    }
  }

  private touchMove (e:any, key: number) {
    if (this.touchKey !== key) {
      this.isDeltaX = false
    }
    this.touchKey = key
    const touches = e.changedTouches[0]
    const delta = {
      x: touches.pageX - this.startPoint.x,
      y: touches.pageY - this.startPoint.y
    }
    // 横向位移大于纵向位移，阻止纵向滚动
    if (Math.abs(delta.x) > Math.abs(delta.y)) {
      e.preventDefault()
    }
  }

  private touchEnd (e:any) {
    // console.log(e)
    const diffX = e.changedTouches[0].pageX - this.startPoint.x
    if (diffX < -50) {
      // console.log('向左')
      this.isDeltaX = true
    } else if (diffX > 50) {
      // console.log('向右')
      this.isDeltaX = false
    }
  }
}
